import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Refresh1 } from '../../../../components/images/support/xero/Refresh1.generated';
import { Refresh2 } from '../../../../components/images/support/xero/Refresh2.generated';
import { Refresh3 } from '../../../../components/images/support/xero/Refresh3.generated';
import { Refresh4 } from '../../../../components/images/support/xero/Refresh4.generated';
import { Refresh5 } from '../../../../components/images/support/xero/Refresh5.generated';
import { Refresh6 } from '../../../../components/images/support/xero/Refresh6.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "refreshing-a-xero-connection"
    }}>{`Refreshing a Xero Connection`}</h1>
    <ol>
      <li parentName="ol">{`Navigate to the `}<strong parentName="li">{`settings`}</strong>{` menu, then click the `}<strong parentName="li">{`Disconnect Xero`}</strong>{` button.`}</li>
    </ol>

    <Refresh1 mdxType="Refresh1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Disconnect Xero`}</strong>{` button.`}</li>
    </ol>

    <Refresh2 mdxType="Refresh2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Login to your Xero account, click the menu in the top right and click `}<strong parentName="li">{`Manage app connections`}</strong>{`.`}</li>
    </ol>

    <Refresh3 mdxType="Refresh3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Disconnect`}</strong>{` button for Canyou.`}</li>
    </ol>

    <Refresh4 mdxType="Refresh4" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Confirm the disconnection.`}</li>
    </ol>

    <Refresh5 mdxType="Refresh5" />
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Navigate to the `}<strong parentName="li">{`settings`}</strong>{` menu, then click the `}<strong parentName="li">{`Connect Xero`}</strong>{` button.`}</li>
    </ol>

    <Refresh6 mdxType="Refresh6" />
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Re-establish the connection by following the `}<a parentName="li" {...{
          "href": "/support/integrations/xero/setup"
        }}>{`(Connect to Xero)`}</a>{` documentation`}</li>
    </ol>
    <h1 {...{
      "id": "great-work-your-connection-has-now-been-refreshed"
    }}>{`Great work your connection has now been refreshed`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      